import * as React from 'react'
import { GlobalContext } from '../../../globalContext'
import { Avatar } from '../Avatar'

export function AvatarIcon() {
  const { me } = React.useContext(GlobalContext)
  return (
    <Avatar
      size="tiny"
      src={me && me.user && me.user.image ? me.user.image['32px'] : ''}
      alt={me && me.user ? me.user.name : ''}
    />
  )
}
