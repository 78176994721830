import * as React from 'react'
import styled from 'styled-components'
import { I18nCatalogue } from '../../../i18n/common/types'
import { colors, sizes } from '../../../styleConstants'
import { NavigationItemList } from './NavigationItemList'

interface Props {
  readonly catalogue: I18nCatalogue
}

export function BottomNavigation({ catalogue }: Props) {
  return (
    <Container>
      <NavigationItemList catalogue={catalogue} />
    </Container>
  )
}

const Container = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  height: calc(${sizes.bottomNavigationHeight} + constant(safe-area-inset-bottom));
  height: calc(${sizes.bottomNavigationHeight} + env(safe-area-inset-bottom));
  background: ${colors.secondaryExtraLight};
`
