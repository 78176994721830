import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../styleConstants'

interface OptionProps extends React.OptionHTMLAttributes<HTMLOptionElement> {
  readonly label: string
  readonly value?: string | number
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  readonly options: OptionProps[]
  readonly fluid?: boolean
}

export function Select({ options, fluid, ...rest }: Props) {
  return (
    <DesignedSelect className={cc({ fluid })} {...rest}>
      {options.map(option => {
        return (
          <option key={option.value} {...option}>
            {option.label}
          </option>
        )
      })}
    </DesignedSelect>
  )
}

const DesignedSelect = styled.select`
  padding: 0.5rem calc(1rem + 24px) 0.5rem 1rem;
  color: ${colors.gray800};
  font-size: 1rem;
  border: 1px solid ${colors.gray300};
  cursor: pointer;
  appearance: none;
  background: ${colors.gray100} no-repeat right url('/static/arrow_drop_down-24px.svg');
  border: none;
  outline: none;

  &.fluid {
    width: 100%;
  }
`
