import * as React from 'react'
import { NextPageContext } from 'next'
import App, { AppContext } from 'next/app'
import * as Sentry from '@sentry/browser'
import { loadCatalogue } from '../frontend-lib/i18n'
import { getUserAgentType, UserAgentType } from '../frontend-lib/detectUserAgent'
import { GlobalContextWrapper } from '../frontend-lib/globalContext'
import { fetchMe, Me, isAdministrator } from '../frontend-lib/types/me'
import { PageLayout } from '../frontend-lib/components/common/PageLayout/PageLayout'
import { I18nCatalogue } from '../frontend-lib/i18n/common/types'
import { routeFactories } from '../frontend-lib/routes'
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

Sentry.init({ dsn: 'https://6cbade25b4f34e0cb5bfae191494aa2a@sentry.io/1530691' })

interface Props {
  readonly detectedUserAgent: UserAgentType
  readonly me?: Me
  readonly catalogue: I18nCatalogue
}

export interface KamismaPageContext extends NextPageContext {
  me?: Me
}

export default class KamismaApp extends App<Props> {
  static async getInitialProps({ Component, ctx }: AppContext) {
    let pageProps = {}
    const detectedUserAgent = getUserAgentType(ctx.req)
    const me = await fetchMe(ctx.req)
    const catalogue = await loadCatalogue('common', me, ctx.req)
    const kamismaContext: KamismaPageContext = { ...ctx, me }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(kamismaContext)
    }

    // NOTE: Redirect to sign-in page if the page requires `me`.
    if ('requireMe' in pageProps && (pageProps as any).requireMe && !me) {
      const redirectPath = routeFactories.signIn.getRoute().as!
      if (ctx.res) {
        ctx.res
          .writeHead(302, {
            Location: redirectPath,
          })
          .end()
      } else {
        location.href = redirectPath
      }
    }

    // admin page access restriction
    if ('requireAdmin' in pageProps && (pageProps as any).requireAdmin && !isAdministrator(me)) {
      if (ctx.res) {
        ctx.res.writeHead(302, { Location: '/_error' })
        ctx.res.end()
      }
    }

    return { pageProps, detectedUserAgent, me, catalogue }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, detectedUserAgent, me, catalogue } = this.props

    return (
      <GlobalContextWrapper detectedUserAgent={detectedUserAgent} me={me}>
        <PageLayout catalogue={catalogue}>
          <GoogleReCaptchaProvider reCaptchaKey="6LcVD8EnAAAAAKCPJ0rjE4nDpfqN_unMpfpZjrLS">
            <Component {...pageProps} />
          </GoogleReCaptchaProvider>
        </PageLayout>
      </GlobalContextWrapper>
    )
  }
}
