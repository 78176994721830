import * as React from 'react'
import { sizes } from '../../../styleConstants'

export enum WindowSize {
  Small,
  Medium,
  Large,
}

interface State {
  readonly initializing: boolean
  readonly windowSize: WindowSize
}

export function usePageLayoutState() {
  const [state, setState] = React.useState<State>({ initializing: true, windowSize: WindowSize.Small })
  const eventHandler = () => {
    const windowSize = getWindowSize()
    setState({ ...state, windowSize })
  }

  React.useEffect(() => {
    const windowSize = getWindowSize()
    setState({ initializing: false, windowSize })
  }, []) // NOTE: Run only once

  React.useEffect(() => {
    window.addEventListener('resize', eventHandler, { passive: true })
    return () => {
      window.removeEventListener('resize', eventHandler, false)
    }
  }, [eventHandler])

  return state
}

function getWindowSize(): WindowSize {
  const minWidth = parseInt(sizes.pageMaxWidth) + parseInt(sizes.navigationDrawerWidth) * 2
  switch (true) {
    case window.matchMedia(`(min-width: ${minWidth}px)`).matches:
      return WindowSize.Large
    case window.matchMedia(`(min-width: 768px)`).matches:
      return WindowSize.Medium
    default:
      return WindowSize.Small
  }
}
