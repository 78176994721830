import * as React from 'react'
import styled from 'styled-components'
import { colors, sizes } from '../../styleConstants'
import { I18nCatalogue } from '../../i18n/common/types'
import { Button } from './Button'
import { routeFactories } from '../../routes'
import { getPreferredLanguage } from '../../i18n'

interface Props {
  readonly catalogue: I18nCatalogue
}

export function Footer({ catalogue }: Props) {
  const now = new Date()
  const year = now.getFullYear() === 2019 ? '2019' : `2019-${now.getFullYear()}`

  return (
    <Container>
      <Main>
        {false && <Item look="transparent">{catalogue.footer.help}</Item>}
        <Item
          look="transparent"
          route={
            getPreferredLanguage() == 'ja'
              ? routeFactories.serviceTerm.getRoute()
              : routeFactories.serviceTerm_EN.getRoute()
          }>
          {catalogue.footer.terms}
        </Item>
        <Item
          look="transparent"
          route={
            getPreferredLanguage() == 'ja'
              ? routeFactories.privacyPolicy.getRoute()
              : routeFactories.privacyPolicy_EN.getRoute()
          }>
          {catalogue.footer.privacy}
        </Item>
      </Main>
      {/* TODO: KAMISMA or ifing? */}
      <Copyright>&copy; {year} KAMISMA BEAUTY</Copyright>
      <Copyright>This site is protected by reCAPTCHA and the Google&nbsp;
        <a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;and&nbsp;
        <a href="https://policies.google.com/terms">Terms of Service</a>&nbsp;apply.
      </Copyright>
    </Container>
  )
}

const Container = styled.footer`
  width: 100%;
  color: ${colors.white};
  background-color: ${colors.brown};
`

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${sizes.pageMaxWidth};
  margin: 0 auto;
  padding: 1rem 0;
`

const Item = styled(Button)`
  & + & {
    margin-left: 2rem;
  }
`

const Copyright = styled.div`
  display: flex;
  justify-content: center;
  background: ${colors.brownDark};
  padding: 1rem;
  font-size: 0.8rem;
`
