import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { Close } from 'styled-icons/material'
import { boxShadows, colors, sizes, zIndices } from '../../styleConstants'
import { Button } from './Button'

export interface Props {
  readonly text: string
  readonly close: () => void
  readonly action?: {
    readonly label: string
    readonly onClick: () => void
  }
  readonly style?: React.CSSProperties
}

export function Snackbar({ text, close, action, style }: Props) {
  React.useEffect(() => {
    const timeoutId = window.setTimeout(close, 5000)
    return () => window.clearTimeout(timeoutId)
  })

  return (
    <Container style={style}>
      <Text>{text}</Text>
      <Buttons>
        {action && (
          <ActionButton look="dark" onClick={action.onClick}>
            {action.label}
          </ActionButton>
        )}
        <Button look="dark" onClick={close}>
          <Close size="24" />
        </Button>
      </Buttons>
    </Container>
  )
}

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Container = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  max-width: calc(${sizes.pageMaxWidth} * 0.4);
  min-width: calc(${sizes.pageMinWidth} * 0.95);
  animation: ${appearAnimation} 0.15s ease-out;
  background: ${colors.gray800};
  color: ${colors.white};
  padding: 0.5rem 1rem;
  z-index: ${zIndices.dialog};
  box-shadow: ${boxShadows.z8};
`

const Text = styled.div``

const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const ActionButton = styled(Button)`
  color: ${colors.secondaryLight};
  margin-right: 0.5rem;
  font-weight: bold;
`
