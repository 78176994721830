import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { Button } from './Button'
import { boxShadows, colors, zIndices } from '../../styleConstants'
import { MouseEventHandler } from 'react'

interface Props {
  readonly trigger: JSX.Element
  readonly place?: 'left' | 'right'
  readonly className?: string
  readonly style?: React.CSSProperties
  readonly children: JSX.Element[] | JSX.Element
  readonly 'data-test'?: string
}

interface State {
  readonly opened: boolean
}

export function Dropdown({
  trigger,
  place = 'left',
  className,
  style,
  children: originalChildren,
  'data-test': dataTest,
}: Props) {
  const children: JSX.Element[] = Array.isArray(originalChildren) ? originalChildren : [originalChildren]
  const [state, setState] = React.useState<State>({ opened: false })
  const container = React.useRef<HTMLDivElement>(null)
  const closeMenu = (event: Event) => {
    const currentContainer = container.current
    const target = event.target
    if (event instanceof KeyboardEvent) {
      if (event.key === 'Escape') {
        setState({ opened: false })
      }
      return
    }
    if (currentContainer && target && target instanceof HTMLElement && currentContainer.contains(target)) {
      return
    }
    setState({ opened: false })
  }

  React.useEffect(() => {
    document.addEventListener('click', closeMenu)
    document.addEventListener('keydown', closeMenu)
    return () => {
      document.removeEventListener('click', closeMenu)
      document.removeEventListener('keydown', closeMenu)
    }
  })

  return (
    <Container ref={container} className={className} style={style} data-test={dataTest}>
      <Button
        look="white"
        onClick={() => setState({ opened: !state.opened })}
        role="button"
        aria-haspopup
        aria-expanded={state.opened}
        data-test={trigger.props['data-test']}>
        {React.cloneElement(trigger, {
          'data-test': undefined,
          style: { pointerEvents: 'none' },
        }) /* NOTE: Make clickable trigger if it's SVG */}
      </Button>
      <Menu opened={state.opened} place={place}>
        {children.map((child, key) => {
          const onClick: MouseEventHandler<Element> = event => {
            setState({ opened: false })
            child.props.onClick && child.props.onClick(event)
          }
          return <Item onClick={onClick} component={child} key={key} />
        })}
      </Menu>
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
  position: relative;
`

const menuTransition = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

interface MenuProps {
  readonly opened: boolean
  readonly place: Props['place']
}

const Menu = styled.div<MenuProps>`
  position: absolute;
  left: ${props => (props.place === 'left' ? '0' : 'auto')};
  right: ${props => (props.place === 'right' ? '0' : 'auto')};
  display: ${props => (props.opened ? 'flex' : 'none')};
  flex-direction: column;
  animation: ${menuTransition} 0.15s ease-out;
  background: ${colors.white};
  border-radius: 3px;
  overflow: hidden;
  font-size: 0.9rem;
  padding: 0.25rem 0;
  box-shadow: ${boxShadows.z8};
  z-index: ${zIndices.dropdown};
`

const Item = styled(({ component, ...props }) => React.cloneElement(component, props))`
  padding: 0.5em 1em;
  cursor: pointer;
  white-space: nowrap;

  &:hover,
  &:focus {
    background: ${colors.gray200};
    text-decoration: none;
  }
`
