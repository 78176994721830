import * as React from 'react'
import Cookies from 'universal-cookie'
import { Dialog } from '../Dialog'
import { I18nCatalogue } from '../../../i18n/common/types'
import { Language, LanguageOfFormalName, PreferredLanguageKeyInCookie } from '../../../i18n/types'
import { Select } from '../Select'
import { HeaderState } from './useHeaderState'
import { getPreferredLanguage } from '../../../i18n'

interface Props {
  readonly catalogue: I18nCatalogue
  readonly headerState: HeaderState
}

interface State {
  readonly selectedLanguage: Language
}

export function LanguageSelection({ catalogue, headerState }: Props) {
  const selectedLanguage = getPreferredLanguage()
  const [state, setState] = React.useState<State>({ selectedLanguage })
  const close = () => headerState.setState({ ...headerState, languageSelection: false })
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState({ ...state, selectedLanguage: event.target.value as Language })
  }
  const onDone = () => {
    const cookies = new Cookies()
    cookies.set(PreferredLanguageKeyInCookie, state.selectedLanguage)
    location.reload()
  }

  return (
    <Dialog
      title={catalogue.header.languageDialogTitle}
      data-test="choose-preferred-language-dialog"
      onClose={close}
      actions={[
        { look: 'white', label: catalogue.cancel, onClick: close },
        { look: 'primary', label: catalogue.done, onClick: onDone },
      ]}>
      <Select
        defaultValue={state.selectedLanguage}
        options={[
          {
            label: LanguageOfFormalName(Language.English),
            value: Language.English,
          },
          {
            label: LanguageOfFormalName(Language.Japanese),
            value: Language.Japanese,
          },
        ]}
        onChange={onChange}
        fluid
      />
    </Dialog>
  )
}
