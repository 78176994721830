import * as React from 'react'
import { WindowSize } from '../PageLayout/usePageLayoutState'

export enum NavigationDrawerMode {
  Draweable,
  Pin,
}

interface State {
  readonly opened: boolean
  readonly mode: NavigationDrawerMode
}

interface Actions {
  readonly open: () => void
  readonly close: () => void
  readonly toggle: () => void
}

export interface NavigationDrawerState {
  readonly state: State
  readonly actions: Actions
}

export function useNavigationDrawerState(windowSize: WindowSize): NavigationDrawerState {
  const [state, setState] = React.useState<State>({ opened: false, mode: NavigationDrawerMode.Draweable })

  React.useEffect(() => {
    if (windowSize === WindowSize.Large) {
      setState({ opened: true, mode: NavigationDrawerMode.Pin })
    } else {
      setState({ opened: false, mode: NavigationDrawerMode.Draweable })
    }
  }, [windowSize])

  const actions: Actions = {
    open: () => setState({ ...state, opened: true }),
    close: () => setState({ ...state, opened: false }),
    toggle: () => setState({ ...state, opened: !state.opened }),
  }

  return { state, actions }
}
