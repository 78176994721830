import * as React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import cc from 'classcat'
import {
  ChatBubble,
  Home as HomeIcon,
  Person,
  Search,
  Today,
  Dashboard,
  SupervisorAccount,
  History,
} from 'styled-icons/material'
import { Button, Props as ButtonProps } from '../Button'
import { colors } from '../../../styleConstants'
import { Route, routeFactories } from '../../../routes'
import { I18nCatalogue } from '../../../i18n/common/types'

interface Props {
  readonly catalogue: I18nCatalogue
  readonly salonId?: number
  readonly stylistId?: number
}

export function Home({ catalogue }: Props) {
  return (
    <NavItem route={routeFactories.root.getRoute()} icon={<HomeIcon size="24" />} label={catalogue.navigation.home} />
  )
}

export function Explore({ catalogue }: Props) {
  return (
    <NavItem
      route={routeFactories.explore.getRoute()}
      icon={<Search size="24" />}
      label={catalogue.navigation.explore}
    />
  )
}

export function Calendar({ catalogue, stylistId }: Props) {
  return (
    <NavItem
      route={routeFactories.reservation.getRoute(stylistId!)}
      icon={<Today size="24" />}
      label={catalogue.navigation.calendar}
    />
  )
}

export function ReservationHistory({ catalogue }: Props) {
  return (
    <NavItem
      route={routeFactories.reservationHistory.getRoute()}
      icon={<History size="24" />}
      label={catalogue.navigation.reservationHistory}
    />
  )
}

export function Inbox({ catalogue }: Props) {
  // TODO: route
  return (
    <NavItem
      route={routeFactories.root.getRoute()}
      icon={<ChatBubble size="24" />}
      label={catalogue.navigation.inbox}
    />
  )
}

export function Conversations({ catalogue }: Props) {
  // TODO: route
  return (
    <NavItem
      route={routeFactories.root.getRoute()}
      icon={<ChatBubble size="24" />}
      label={catalogue.navigation.conversations}
    />
  )
}

export function Profile({ catalogue }: Props) {
  return (
    <NavItem
      route={routeFactories.profile.getRoute()}
      icon={<Person size="24" />}
      label={catalogue.navigation.profile}
    />
  )
}

export function SalonsEdit({ catalogue, salonId }: Props) {
  return (
    <NavItem
      route={routeFactories.salonsEdit.getRoute(salonId!)}
      icon={<Dashboard size="24" />}
      label={catalogue.navigation.salonsEdit}
    />
  )
}

export function SalonsMember({ catalogue, salonId }: Props) {
  return (
    <NavItem
      route={routeFactories.salonsMember.getRoute(salonId!)}
      icon={<SupervisorAccount size="24" />}
      label={catalogue.navigation.salonsMember}
    />
  )
}

export function AdminDashboard({ catalogue }: Props) {
  return (
    <NavItem
      route={routeFactories.adminDashboard.getRoute()}
      icon={<Dashboard size="24" />}
      label={catalogue.navigation.adminDashboard}
    />
  )
}

interface NavItemProps {
  readonly route: Route
  readonly icon: ButtonProps['iconLeft']
  readonly label: string
}

function NavItem({ route, icon, label }: NavItemProps) {
  const router = useRouter()
  const active = router.pathname === route.href.pathname

  return (
    <Item look="white" route={route} className={cc({ active })}>
      {icon}
      {label}
    </Item>
  )
}

const Item = styled(Button)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 20%;
  height: 100%;
  background: transparent;
  border-radius: 0;
  font-size: 11px;
  color: ${colors.secondary};

  &.active {
    color: ${colors.primary};
  }
`
