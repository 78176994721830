import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { Menu, Close } from 'styled-icons/material'
import { colors, sizes, zIndices } from '../../../styleConstants'
import { Link } from '../Link'
import { Button } from '../Button'
import { I18nCatalogue } from '../../../i18n/common/types'
import { routeFactories } from '../../../routes'
import { NavigationDrawerState } from '../NavigationDrawer/useNagationDrawerState'
import { WindowSize } from '../PageLayout/usePageLayoutState'
import { RowItems } from './RowItems'
import { HeaderDialog } from './HeaderDialog'
import { LanguageSelection } from './LanguageSelection'
import { useHeaderState } from './useHeaderState'
import { AvatarIcon } from './AvatarIcon'

interface Props {
  readonly catalogue: I18nCatalogue
  readonly windowSize: WindowSize
  readonly navigationDrawerState?: NavigationDrawerState
}

export function Header({ catalogue, windowSize, navigationDrawerState }: Props) {
  const collapsed = windowSize === WindowSize.Small
  const headerState = useHeaderState()
  const { opened, setState } = headerState

  return (
    <Container className={cc({ sticky: !!navigationDrawerState })} data-test="header">
      {navigationDrawerState && (
        <LeftTrigger look="white" onClick={navigationDrawerState.actions.toggle} disabled={collapsed && opened}>
          <Menu size="24" />
        </LeftTrigger>
      )}
      <Logo route={(collapsed && !opened) || !collapsed ? routeFactories.root.getRoute() : undefined}>
        <img src="/static/kamisma_logo.png" height={80} />
      </Logo>
      {collapsed && (
        <RightTrigger look="white" onClick={() => setState({ ...headerState, opened: !opened })}>
          {!opened && <AvatarIcon data-test="account-icon" />}
          {opened && <Close size="24" />}
        </RightTrigger>
      )}
      {!collapsed && <RowItems headerState={headerState} catalogue={catalogue} />}
      {collapsed && opened && <HeaderDialog headerState={headerState} catalogue={catalogue} />}
      {headerState.languageSelection && <LanguageSelection headerState={headerState} catalogue={catalogue} />}
    </Container>
  )
}

const Container = styled.header`
  width: 100%;
  height: ${sizes.headerHeight};
  background-color: ${colors.white};
  border-top: 5px solid ${colors.brown};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray300};

  &.sticky {
    position: sticky;
    top: 0;
    z-index: ${zIndices.header};
  }
`

const Logo = styled(Link)`
  color: inherit;
  padding: 1rem;
`

const LeftTrigger = styled(Button)`
  &[disabled] {
    cursor: default;

    &:hover,
    &:focus,
    &:active {
      background-color: inherit;
      filter: none;
    }
  }
`

const RightTrigger = styled(Button)`
  margin-left: auto;
`
