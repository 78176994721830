import * as React from 'react'
import { I18nCatalogue } from '../../../i18n/common/types'
import { GlobalContext } from '../../../globalContext'
import { isHairStylist, isAdministrator } from '../../../types/me'
import { Calendar, Explore, Home, Profile, AdminDashboard, SalonsEdit, SalonsMember, ReservationHistory } from './Items'

interface Props {
  readonly catalogue: I18nCatalogue
}

export function NavigationItemList({ catalogue }: Props) {
  const { me } = React.useContext(GlobalContext)

  function createSalonManegeButtonElements() {
    if (me!.hairStylist!.salons.length <= 0) {
      return null
    }

    const salon = me!.hairStylist!.salons[0]
    if (!salon) {
      return null
    }

    return (
      <>
        <SalonsEdit catalogue={catalogue} salonId={salon.id} />
        <SalonsMember catalogue={catalogue} salonId={salon.id} />
      </>
    )
  }

  switch (true) {
    case me && isAdministrator(me):
      return (
        <>
          <Home catalogue={catalogue} />
          <AdminDashboard catalogue={catalogue} />
          <Profile catalogue={catalogue} />
        </>
      )
    case me && isHairStylist(me):
      const salonManegeButtons = createSalonManegeButtonElements()
      return (
        <>
          <Home catalogue={catalogue} />
          {/*<Conversations catalogue={catalogue} />*/}
          <Calendar catalogue={catalogue} stylistId={me!.hairStylist!.id} />
          <Profile catalogue={catalogue} />
          {me!.salonAuth && salonManegeButtons}
        </>
      )
    case me !== undefined:
      return (
        <>
          <Home catalogue={catalogue} />
          <Explore catalogue={catalogue} />
          <ReservationHistory catalogue={catalogue} />
          {/*<Inbox catalogue={catalogue} />*/}
          <Profile catalogue={catalogue} />
        </>
      )
    default:
      return (
        <>
          <Home catalogue={catalogue} />
          <Explore catalogue={catalogue} />
        </>
      )
  }
}
