import * as React from 'react'

interface State {
  readonly opened: boolean
  readonly languageSelection: boolean
}

export interface HeaderState extends State {
  readonly setState: React.Dispatch<React.SetStateAction<State>>
}

export function useHeaderState() {
  const [state, setState] = React.useState<State>({ opened: false, languageSelection: false })
  return { ...state, setState }
}
