import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { Header } from '../Header/Header'
import { I18nCatalogue } from '../../../i18n/common/types'
import { Footer } from '../Footer'
import { UserAgentType } from '../../../detectUserAgent'
import { GlobalContext } from '../../../globalContext'
import { NavigationDrawer } from '../NavigationDrawer/NavigationDrawer'
import { NavigationDrawerMode, useNavigationDrawerState } from '../NavigationDrawer/useNagationDrawerState'
import { BottomNavigation } from '../BottomNavigation/BottomNavigation'
import { sizes } from '../../../styleConstants'
import { usePageLayoutState } from './usePageLayoutState'
import { Snackbar } from '../Snackbar'

interface Props {
  readonly catalogue: I18nCatalogue
  readonly children?: React.ReactNode
}

export function PageLayout({ catalogue, children }: Props) {
  const { detectedUserAgent, me, notifications, updateContext } = React.useContext(GlobalContext)
  const { windowSize, initializing } = usePageLayoutState()
  const navigationDrawerState = useNavigationDrawerState(windowSize)
  const { mode, opened } = navigationDrawerState.state
  const pin = mode === NavigationDrawerMode.Pin
  const closeCurrentNotification = () =>
    updateContext(draft => {
      draft.notifications = notifications.slice(1)
    })

  if (initializing) return null

  switch (detectedUserAgent) {
    case UserAgentType.Desktop:
      return (
        <Container>
          <Header catalogue={catalogue} windowSize={windowSize} navigationDrawerState={navigationDrawerState} />
          <NavigationDrawer catalogue={catalogue} navigationDrawerState={navigationDrawerState} />
          <Main className={cc({ opened, pin })}>{children}</Main>
          <Footer catalogue={catalogue} />
          {notifications.slice(0, 1).map(notification => {
            return (
              <Snackbar
                key={notification.id || notification.text}
                text={notification.text}
                close={closeCurrentNotification}
                action={notification.action}
              />
            )
          })}
        </Container>
      )
    case UserAgentType.Mobile:
      return (
        <Container>
          <Header catalogue={catalogue} windowSize={windowSize} />
          <Main>{children}</Main>
          {me === undefined && <Footer catalogue={catalogue} />}
          {me && <BottomNavigation catalogue={catalogue} />}
          {notifications.slice(0, 1).map(notification => {
            return (
              <Snackbar
                key={notification.text}
                text={notification.text}
                close={closeCurrentNotification}
                action={notification.action}
                style={{ bottom: `calc(${sizes.bottomNavigationHeight} + 0.5rem)` }}
              />
            )
          })}
        </Container>
      )
  }
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const Main = styled.main`
  flex: 1 0;

  &.pin.opened {
    margin-left: ${sizes.navigationDrawerWidth};
  }
`
