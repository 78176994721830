import * as React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import cc from 'classcat'
import {
  ChatBubble,
  Home as HomeIcon,
  Person,
  Search,
  Today,
  Dashboard,
  SupervisorAccount,
  History,
} from 'styled-icons/material'
import { Button, Props as ButtonProps } from '../Button'
import { colors } from '../../../styleConstants'
import { Route, routeFactories } from '../../../routes'
import { I18nCatalogue } from '../../../i18n/common/types'

interface Props {
  readonly catalogue: I18nCatalogue
  readonly onClick?: React.MouseEventHandler
  readonly salonId?: number
  readonly stylistId?: number
}

export function Home({ catalogue, onClick }: Props) {
  return (
    <NavItem
      route={routeFactories.root.getRoute()}
      icon={<HomeIcon size="24" />}
      label={catalogue.navigation.home}
      onClick={onClick}
    />
  )
}

export function Explore({ catalogue, onClick }: Props) {
  return (
    <NavItem
      route={routeFactories.explore.getRoute()}
      icon={<Search size="24" />}
      label={catalogue.navigation.explore}
      onClick={onClick}
    />
  )
}

export function Calendar({ catalogue, stylistId, onClick }: Props) {
  return (
    <NavItem
      route={routeFactories.reservation.getRoute(stylistId!)}
      icon={<Today size="24" />}
      label={catalogue.navigation.calendar}
      onClick={onClick}
    />
  )
}

export function ReservationHistory({ catalogue, onClick }: Props) {
  return (
    <NavItem
      route={routeFactories.reservationHistory.getRoute()}
      icon={<History size="24" />}
      label={catalogue.navigation.reservationHistory}
      onClick={onClick}
    />
  )
}

export function Inbox({ catalogue, onClick }: Props) {
  // TODO: route
  return (
    <NavItem
      route={routeFactories.root.getRoute()}
      icon={<ChatBubble size="24" />}
      label={catalogue.navigation.inbox}
      onClick={onClick}
    />
  )
}

export function Conversations({ catalogue, onClick }: Props) {
  // TODO: route
  return (
    <NavItem
      route={routeFactories.root.getRoute()}
      icon={<ChatBubble size="24" />}
      label={catalogue.navigation.conversations}
      onClick={onClick}
    />
  )
}

export function Profile({ catalogue, onClick }: Props) {
  return (
    <NavItem
      route={routeFactories.profile.getRoute()}
      icon={<Person size="24" />}
      label={catalogue.navigation.profile}
      onClick={onClick}
    />
  )
}

export function SalonsEdit({ catalogue, salonId }: Props) {
  return (
    <NavItem
      route={routeFactories.salonsEdit.getRoute(salonId!)}
      icon={<Dashboard size="24" />}
      label={catalogue.navigation.salonsEdit}
    />
  )
}

export function SalonsMember({ catalogue, salonId }: Props) {
  return (
    <NavItem
      route={routeFactories.salonsMember.getRoute(salonId!)}
      icon={<SupervisorAccount size="24" />}
      label={catalogue.navigation.salonsMember}
    />
  )
}

export function AdminDashboard({ catalogue, onClick }: Props) {
  return (
    <NavItem
      route={routeFactories.adminDashboard.getRoute()}
      icon={<Dashboard size="24" />}
      label={catalogue.navigation.adminDashboard}
      onClick={onClick}
    />
  )
}

interface NavItemProps {
  readonly route: Route
  readonly icon: ButtonProps['iconLeft']
  readonly label: string
  readonly onClick?: React.MouseEventHandler
}

function NavItem({ route, icon, label, onClick }: NavItemProps) {
  const router = useRouter()
  const active = router.pathname === route.href.pathname

  return (
    <Item look="white" route={route} iconLeft={icon} onClick={onClick} className={cc({ active })}>
      {label}
    </Item>
  )
}

const Item = styled(Button)`
  color: ${colors.secondary};
  background: transparent;
  width: 100%;

  &.active {
    color: ${colors.primary};
  }
`
