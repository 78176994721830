import * as React from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'
import styled, { keyframes } from 'styled-components'
import { Button, Props as ButtonProps } from './Button'
import { boxShadows, colors, sizes, zIndices } from '../../styleConstants'

interface Action {
  readonly label: string
  readonly look: ButtonProps['look']
  readonly onClick?: () => void
  readonly disabled?: boolean
}

interface Props {
  readonly onClose: () => void
  readonly title?: string
  readonly actions?: Action[]
  readonly children?: React.ReactNode
  readonly 'data-test'?: string
}

export function Dialog({ title, actions, onClose, children, 'data-test': dataTest }: Props) {
  const handleEscapeKey = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose],
  )
  const overlay = React.useRef<HTMLDivElement>(null)
  const onOverlayClick = (event: React.MouseEvent) => {
    if (event.target === overlay.current) {
      onClose()
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey)
    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  })

  return createPortal(
    <RemoveScroll removeScrollBar={false}>
      <Overlay ref={overlay} onClick={onOverlayClick} data-test={dataTest}>
        <Container>
          {title && <Title>{title}</Title>}
          <Content>{children}</Content>
          {actions && (
            <Actions>
              {actions.map(action => {
                return (
                  <ActionButton
                    key={action.label}
                    look={action.look}
                    onClick={action.onClick || onClose}
                    disabled={action.disabled}>
                    {action.label}
                  </ActionButton>
                )
              })}
            </Actions>
          )}
        </Container>
      </Overlay>
    </RemoveScroll>,
    document.body,
  )
}

const appearAnimation = keyframes`
  from {
    transform: scale(0.94) translate(0, 1rem);
  }

  to {
    transform: scale(1) translate(0, 0);
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 50px 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${zIndices.dialog};
`

const Container = styled.div`
  display: block;
  margin: 0 auto;
  background: ${colors.white};
  box-shadow: ${boxShadows.z16};
  border-radius: 4px;
  max-width: calc(${sizes.pageMaxWidth} * 0.5);
  width: 75vw;
  min-width: ${sizes.pageMinWidth};
  animation: ${appearAnimation} 0.2s ease-out;
`

const Title = styled.h2`
  padding: 1rem;
  margin: 0;
`

const Content = styled.div`
  padding: 1rem;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
`

const ActionButton = styled(Button)`
  & + & {
    margin-left: 0.5rem;
  }
`
