import * as React from 'react'
import styled from 'styled-components'
import { I18nCatalogue } from '../../../i18n/common/types'
import { GlobalContext } from '../../../globalContext'
import { isHairStylist, isAdministrator } from '../../../types/me'
import { Calendar, Explore, Home, Profile, AdminDashboard, SalonsEdit, SalonsMember, ReservationHistory } from './Items'

interface Props {
  readonly catalogue: I18nCatalogue
  readonly close: () => void
}

export function NavigationItemList({ catalogue, close }: Props) {
  const { me } = React.useContext(GlobalContext)

  function createSalonManegeButtonElements() {
    if (me!.hairStylist!.salons.length <= 0) {
      return null
    }

    const salon = me!.hairStylist!.salons[0]
    if (!salon) {
      return null
    }

    return (
      <>
        <Li>
          <SalonsEdit catalogue={catalogue} onClick={close} salonId={salon.id} />
        </Li>
        <Li>
          <SalonsMember catalogue={catalogue} onClick={close} salonId={salon.id} />
        </Li>
      </>
    )
  }

  switch (true) {
    case me && isAdministrator(me):
      return (
        <Container>
          <Li>
            <Home catalogue={catalogue} onClick={close} />
          </Li>
          <Li>
            <AdminDashboard catalogue={catalogue} onClick={close} />
          </Li>
          <Li>
            <Profile catalogue={catalogue} onClick={close} />
          </Li>
        </Container>
      )
    case me && isHairStylist(me):
      const salonManegeButtons = createSalonManegeButtonElements()
      return (
        <Container>
          <Li>
            <Home catalogue={catalogue} onClick={close} />
          </Li>
          {/*<Li>
            <Conversations catalogue={catalogue} onClick={close} />
          </Li>*/}
          <Li>
            <Calendar catalogue={catalogue} onClick={close} stylistId={me!.hairStylist!.id} />
          </Li>
          <Li>
            <ReservationHistory catalogue={catalogue} onClick={close} />
          </Li>
          <Li>
            <Profile catalogue={catalogue} onClick={close} />
          </Li>
          {me!.salonAuth && salonManegeButtons}
        </Container>
      )
    case me !== undefined:
      return (
        <Container>
          <Li>
            <Home catalogue={catalogue} onClick={close} />
          </Li>
          <Li>
            <Explore catalogue={catalogue} onClick={close} />
          </Li>
          <Li>
            <ReservationHistory catalogue={catalogue} onClick={close} />
          </Li>
          {/*<Li>
            <Inbox catalogue={catalogue} onClick={close} />
          </Li>*/}
          <Li>
            <Profile catalogue={catalogue} onClick={close} />
          </Li>
        </Container>
      )
    default:
      return (
        <Container>
          <Li>
            <Home catalogue={catalogue} onClick={close} />
          </Li>
          <Li>
            <Explore catalogue={catalogue} onClick={close} />
          </Li>
        </Container>
      )
  }
}

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Li = styled.li``
