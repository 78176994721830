import * as React from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import styled from 'styled-components'
import { I18nCatalogue } from '../../../i18n/common/types'
import { Button } from '../Button'
import { Person, Language, AccountBox, ExitToApp } from 'styled-icons/material'
import { colors, sizes, zIndices } from '../../../styleConstants'
import { apiRoutes, routeFactories } from '../../../routes'
import { GlobalContext } from '../../../globalContext'
import { request } from '../../../utils/request'
import { apiURL } from '../../../utils/url'
import { HeaderState } from './useHeaderState'

interface Props {
  readonly headerState: HeaderState
  readonly catalogue: I18nCatalogue
}

export function HeaderDialog({ headerState, catalogue }: Props) {
  const { me } = React.useContext(GlobalContext)
  const handleEscapeKey = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        headerState.setState({ ...headerState, opened: false })
      }
    },
    [headerState],
  )
  const signOut = async () => {
    headerState.setState({ ...headerState, opened: false })
    await request(apiURL(apiRoutes.signOut), { method: 'DELETE' })
    location.href = routeFactories.root.pathname
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey)
    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  })

  if (me) {
    return (
      <Container>
        <RemoveScroll removeScrollBar={false}>
          <NavButton
            look="white"
            iconLeft={<AccountBox size="24" />}
            route={routeFactories.profile.getRoute()}
            onClick={() => headerState.setState({ ...headerState, opened: false })}>
            {catalogue.header.profile}
          </NavButton>
          <Divider />
          <NavButton look="white" iconLeft={<ExitToApp size="24" />} onClick={signOut}>
            {catalogue.header.signOut}
          </NavButton>
        </RemoveScroll>
      </Container>
    )
  }
  return (
    <Container>
      <RemoveScroll removeScrollBar={false}>
        <NavButton
          look="white"
          iconLeft={<Person size="24" />}
          route={routeFactories.signUp.getRoute()}
          onClick={() => headerState.setState({ ...headerState, opened: false })}>
          {catalogue.header.signUp}
        </NavButton>
        <Divider />
        <NavButton
          look="white"
          iconLeft={<Person size="24" />}
          route={routeFactories.signIn.getRoute()}
          onClick={() => headerState.setState({ ...headerState, opened: false })}>
          {catalogue.header.signIn}
        </NavButton>
        <NavButton
          look="white"
          iconLeft={<Language size="24" />}
          onClick={() => headerState.setState({ ...headerState, opened: false, languageSelection: true })}>
          {catalogue.header.language}
        </NavButton>
      </RemoveScroll>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: ${sizes.headerHeight};
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.white};
  z-index: ${zIndices.dialog};
  padding: 0.5rem 1rem;
`

const Divider = styled.hr`
  margin: 0.5rem 0;
  border: none;
  border-top: 1px solid ${colors.gray400};
  box-sizing: content-box;
  height: 0;
  overflow: visible;
`

const NavButton = styled(Button)`
  width: 100%;
`
