import * as React from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'
import styled, { keyframes } from 'styled-components'
import cc from 'classcat'
import { I18nCatalogue } from '../../../i18n/common/types'
import { colors, sizes, zIndices } from '../../../styleConstants'
import { NavigationItemList } from './NavigationItemList'
import { NavigationDrawerMode, NavigationDrawerState } from './useNagationDrawerState'

interface Props {
  readonly catalogue: I18nCatalogue
  readonly navigationDrawerState: NavigationDrawerState
}

export function NavigationDrawer({ catalogue, navigationDrawerState }: Props) {
  const { mode, opened } = navigationDrawerState.state
  const drawable = mode === NavigationDrawerMode.Draweable
  const { close } = navigationDrawerState.actions
  const handleEscapeKey = React.useCallback(
    (event: KeyboardEvent) => {
      if (opened && drawable && event.key === 'Escape') {
        close()
      }
    },
    [close, opened, drawable],
  )
  const onClose = () => {
    if (opened && drawable) {
      close()
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey)
    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  })

  return (
    <Container className={cc({ opened, drawable })}>
      <RemoveScroll enabled={opened && drawable} removeScrollBar={false}>
        <NavigationItemList catalogue={catalogue} close={onClose} />
        {drawable && opened && createPortal(<Overlay onClick={close} />, document.body)}
      </RemoveScroll>
    </Container>
  )
}

const drawingAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Container = styled.nav`
  position: fixed;
  top: ${sizes.headerHeight};
  bottom: 0;
  z-index: ${zIndices.navigation};
  width: ${sizes.navigationDrawerWidth};
  border-right: 1px solid ${colors.gray300};
  background: ${colors.white};
  display: none;
  padding-top: 2rem;

  &.opened {
    display: block;
  }

  &.drawable {
    top: 0;
    animation: ${drawingAnimation} 0.15s ease-out;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndices.navigation - 1};
  background: rgba(0, 0, 0, 0.5);
  animation: ${opacityAnimation} 0.15s ease-out;
`
