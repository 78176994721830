import * as React from 'react'
import styled from 'styled-components'
import { Button } from '../Button'
import { AccountBox, Language, ExitToApp } from 'styled-icons/material'
import { colors } from '../../../styleConstants'
import { apiRoutes, routeFactories } from '../../../routes'
import { I18nCatalogue } from '../../../i18n/common/types'
import { GlobalContext } from '../../../globalContext'
import { Dropdown } from '../Dropdown'
import { request } from '../../../utils/request'
import { apiURL } from '../../../utils/url'
import { HeaderState } from './useHeaderState'
import { AvatarIcon } from './AvatarIcon'

interface Props {
  readonly headerState: HeaderState
  readonly catalogue: I18nCatalogue
}

export function RowItems({ headerState, catalogue }: Props) {
  const { me } = React.useContext(GlobalContext)
  const signOut = async () => {
    await request(apiURL(apiRoutes.signOut), { method: 'DELETE' })
    location.href = routeFactories.root.pathname
  }

  if (me) {
    return (
      <Dropdown
        trigger={<AvatarIcon data-test="account-icon" />}
        place="right"
        style={{ marginLeft: 'auto' }}
        data-test="account-dropdown">
        <Button look="white" iconLeft={<AccountBox size="24" />} route={routeFactories.profile.getRoute()}>
          {catalogue.header.profile}
        </Button>
        <Button look="white" iconLeft={<ExitToApp size="24" />} onClick={signOut}>
          {catalogue.header.signOut}
        </Button>
      </Dropdown>
    )
  }

  return (
    <Ul>
      <Li>
        <Button
          look="white"
          iconLeft={<Language size="24" />}
          style={{ color: colors.gray500 }}
          onClick={() => headerState.setState({ ...headerState, languageSelection: true })}
          data-test="language-button">
          {catalogue.header.language}
        </Button>
      </Li>
      <Li>
        <Button
          look="white"
          bordered
          style={{ borderColor: colors.secondaryDark }}
          route={routeFactories.signIn.getRoute()}>
          {catalogue.header.signIn}
        </Button>
      </Li>
      <Li>
        <Button look="primary" route={routeFactories.signUp.getRoute()}>
          {catalogue.header.signUp}
        </Button>
      </Li>
    </Ul>
  )
}

const Ul = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0 0 0 auto;
  padding: 1rem 0.5rem;
`

const Li = styled.li`
  & + & {
    margin-left: 1rem;
  }
`
